import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Button, Col, Row } from 'react-bootstrap';

import '../generic-page.scss';
import './play-on-pc.scss';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import { YoutubeEmbed } from '../../modules/common/components/youtube-embed';

const ZZZlayonPCPage: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page play-on-pc-page'} game="zzz">
      <ul className="breadcrumb">
        <li>
          <Link to="/zenless/">Zenless Zone Zero</Link>
        </li>
        <li className="divider">/</li>
        <li>Play on PC</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/zzz/categories/category_characters.png"
            alt="PC"
          />
        </div>
        <div className="page-details">
          <h1>Play ZZZ on PC with BlueStacks</h1>
        </div>
      </div>
      <div className="page-content">
        <div className="banner-zzz-pc">
          <div className="cta">
            <h4>Play Zenless Zone Zero on PC!</h4>
            <OutboundLink href="https://bstk.me/oPVIwb6Ny" target="_blank">
              <Button variant="primary">Play now on BlueStacks</Button>
            </OutboundLink>
          </div>
        </div>
        <SectionHeader title="Play on PC" />
        <p>
          Zenless Zone Zero is an roguelike ARPG game developed by HoYoverse
          that launched globally on the <strong>4th of July 2024</strong>.
        </p>
        <h6>What is BlueStacks?</h6>
        <p>
          Are you tired of constantly switching between your mobile device and
          computer to access your favorite productivity apps? Do you want to
          work more efficiently and conveniently on a larger screen without
          investing in a separate device? Say hello to BlueStacks!
        </p>
        <p>
          BlueStacks is a powerful Android emulator software that lets you run
          Android applications on your computer or laptop, making it the perfect
          solution for productivity-focused users. With BlueStacks, you can
          access thousands of productivity apps and tools without the need for a
          mobile device.
        </p>
        <p>
          One of the most significant advantages of BlueStacks is its
          compatibility with both Windows and Mac operating systems. So whether
          using a PC or a Mac, you can enjoy the convenience of your favorite
          productivity apps and tools on a larger screen with BlueStacks.
        </p>
        <SectionHeader title="Benefits of using BlueStacks" />
        <p>
          Playing Zenless Zone Zero on BlueStacks gives you a lot of advantages:
        </p>
        <ul>
          <li>
            BlueStacks supports multi-instances, so if you're playing multiple
            accounts or want to reroll in a more optimal way, BlueStacks easily
            allows you to do it,
          </li>
          <li>
            BlueStacks allows you to use Macros to optimize your experience -
            you can save a lot of time by creating macros that do the boring
            stuff for you!
          </li>
        </ul>
        <SectionHeader title="Multi-instance" />
        <p>
          If you want to save time while rerolling for your favorite characters,
          then the multi-instance feature available in BlueStacks is perfect for
          you! But it also comes in handy when you play multiple games and want
          to do it at the same time!
        </p>
        <Row className="video-row">
          <Col xs={12} lg={6}>
            <YoutubeEmbed embedId="N2A5WtX2AVA" />
          </Col>
        </Row>
        <SectionHeader title="Recommended PC configuration" />
        <p>
          The following specifications are recommended for playing one or two
          instances:
        </p>
        <ul>
          <li>OS: Microsoft Windows 10 and above,</li>
          <li>
            Processor: Intel or AMD Multi-Core Processor with Single Thread
            benchmark score &gt; 1000
          </li>
          <li>
            Graphics: Intel/Nvidia/ATI, Onboard or Discrete GPU with benchmark
            score &gt;= 750
          </li>
          <li>
            Please ensure{' '}
            <a
              href="https://support.bluestacks.com/hc/en-us/articles/4409279876621"
              target="_blank"
              rel="noreferrer"
              className="social-media-link"
            >
              Virtualization is enabled
            </a>{' '}
            on your PC/Laptop.
          </li>
          <li>RAM: 8GB or higher</li>
          <li>Storage: SSD (or Fusion/Hybrid Drives)</li>
        </ul>
        <p>
          To find the benchmark score of your processor (CPU) and graphics card
          (GPU), read this{' '}
          <a
            href="https://support.bluestacks.com/hc/en-us/articles/360061491412"
            target="_blank"
            rel="noreferrer"
            className="social-media-link"
          >
            article
          </a>{' '}
          .
        </p>
        <SectionHeader title="Play Zenless Zone Zero and support Prydwen" />
        <p>
          If you download BlueStacks using our promotional link and play Zenless
          Zone Zer (or other games) on it, you will support the website. Thank
          you!
        </p>
        <div className="banner-zzz-pc">
          <div className="cta">
            <h4>Play Zenless Zone Zero on PC!</h4>
            <OutboundLink href="https://bstk.me/oPVIwb6Ny" target="_blank">
              <Button variant="primary">Play now on Bluestacks</Button>
            </OutboundLink>
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
};

export default ZZZlayonPCPage;

export const Head: React.FC = () => (
  <Seo
    title="Play on PC | Zenless Zone Zero | Prydwen Institute"
    description="Play Zenless Zone Zero gacha game on your PC."
    game="zzz"
  />
);
